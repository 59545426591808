import { NOT_ERRORS } from '@/shared/config/NOT_ERRORS'

export const filterNotErrors = (error: Error) => {
	for (const notError of NOT_ERRORS) {
		if (error instanceof notError) {
			return false
		}
	}

	return true
}

/**
 * This error is not an actually error
 *
 * We need this for re-requests on search
 *
 * When server send's 202 status on search we throw this error, after
 * handle this in retry and make new request
 *
 * We can delete this after v2 of search
 *
 * **This error should be ignored by react-query and not bubble to ErrorBoundary**
 */
export class SearchWaitError extends Error {
	response: Response

	constructor(message: string, response: Response) {
		super(message)
		this.name = 'Search wait'
		this.response = response
	}
}

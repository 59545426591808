/**
 * Some error with authorization
 *
 * @deprecated We wil remove it in future
 */
export class AuthorizationError extends Error {
	response: Response

	constructor(message: string, response: Response) {
		super(message)
		this.name = 'AuthorizationError'
		this.response = response
	}
}

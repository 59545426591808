import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientOptions } from '@tanstack/react-query-persist-client'

import { DEFAULT_RETRY_COUNT } from '@wwt/shared/config'

import { filterNotErrors } from './shared/utils/filterNotErrors'

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 3 * 60 * 1000, // 3 minutes
			gcTime: Infinity,
			throwOnError: filterNotErrors,
			retry: DEFAULT_RETRY_COUNT
		},
		mutations: {
			throwOnError: filterNotErrors
		}
	}
})

const localStoragePersister =
	typeof window !== 'undefined'
		? createSyncStoragePersister({
				storage: window.localStorage
		  })
		: null

export const persistOptions:
	| Omit<PersistQueryClientOptions, 'queryClient'>
	| undefined = localStoragePersister
	? {
			persister: localStoragePersister,

			dehydrateOptions: {
				shouldDehydrateMutation: mutation => {
					const mutationStringKeysForDehydrate = ['signUp', 'signIn']

					const dehydrate = mutation.options.mutationKey?.some(key =>
						mutationStringKeysForDehydrate.includes(key as string)
					)

					if (dehydrate) {
						return true
					}

					return false
				},
				shouldDehydrateQuery: query => {
					const mutationStringKeysForDehydrate = ['signUp', 'signIn']

					const dehydrate = query.options.queryKey?.some(key =>
						mutationStringKeysForDehydrate.includes(key as string)
					)

					if (dehydrate) {
						return true
					}

					return false
				}
			},
			maxAge: Infinity
	  }
	: undefined

import * as Errors from '../lib/errors'

/**
 * List of an errors which is actually not an errors
 *
 * We need this for trigger react-query mechanisms, like 202 re-request on search
 * or show not found on some requests
 */
export const NOT_ERRORS = [
	Errors.AuthorizationError,
	Errors.SearchWaitError,
	Errors.NotFoundError
]

/**
 * Not found is actually not an error
 *
 * We throw this on 404 server response for show on home/room-offer/reservation
 * and other pages "Offer was not found" message for user
 *
 * We cannot use default Error or WretchError, because we want to ignore this
 * error in QueryClient
 *
 * **This error should be ignored by react-query and not bubble to ErrorBoundary**
 */
export class NotFoundError extends Error {
	response: Response

	constructor(message: string, response: Response) {
		super(message)
		this.name = 'NotFoundError'
		this.response = response
	}
}
